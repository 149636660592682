export const lightTheme = {
  text: "rgb(51, 51, 51)",
  text2: "rgb(74, 74, 74)",
  textFade: "rgba(51, 51, 51, 0.3)",
  textSecondary: "rgb(247, 247, 247)",
  textSecondary2: "rgb(222, 222, 222)",
  textSecondaryFade: "rgba(247, 247, 247, 0.4)",
  bg: "rgb(243, 243, 243)",
  bg2: "rgb(255,255,255)",
  bg3: "rgb(237, 237, 237)",
  bgSecondary: "rgb(20, 20, 20)",
  red: "rgb(200, 117, 117)",
  primary: "rgb(74, 217, 133)",
};